import http from "@/services/http.service";

const apiUrl = "/financial-records";

class FinancialRecordsService {
  static list(params) {
    return http.get(`${apiUrl}`, {params});
  }

  static create(payload) {
    return http.post('records', payload);
  }

  static edit(payload) {
    return http.put('records-update', payload);
  }

  static get(farmId, harvest) {
    return http.get(`${apiUrl}/farms/${farmId}/harvests/${harvest}`);
  }

  static delete(payload) {
    return http.delete('records-destroy', payload);
  }
}

export default FinancialRecordsService;
