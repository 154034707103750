<template>
  <div>
    <v-toolbar class="mb-8" elevation="0" style="background: none">
      <v-toolbar-title class="font-weight-bold">
        Registros Financeiros
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <primary-button
        v-if="authoritySlug === 'super-admin' || permissions.access_records"
        label="+ Adicionar"
        @callAction="addFinancialRecordFarm"/>
    </v-toolbar>

    <search-bar v-model="search" @externalFiltersClear="clearFilters" :noSecondaryInput="false">
      <simple-select :items="financialRecords.map(item => {return item.harvest})"
                     v-model="harvestSearch"
                     class="mr-2"
                     placeholder="Safra"
                     itemText="text"
                     itemValue="value"
                     @input="filterByHarvest"
                     height="0"
                     :menu-props="{ bottom: true, offsetY: true, closeOnClick: true }"
                     returnObject
      />
    </search-bar>

    <!-- Data Table do registro financeiro -->
    <v-data-table
      :footer-props="{
        'items-per-page-text': 'Linhas por página',
        'items-per-page-all-text': 'Todos',
      }"
      :headers="headers"
      :items="financialRecords"
      :search="search.text"
      no-data-text="Não foram encontrados registros."
      class="px-6 pt-6 yellow-border rounded-xl"
      sort-by="id"
      no-results-text="Nenhum registro bate com a busca."
    >

      <template v-slot:[`item.id`]="{ item }">
        {{ '#' + item.id }}
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimezone }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="ml-4" small @click="viewItem(item)">
          mdi-information-outline
        </v-icon>

        <v-icon
          v-if="authoritySlug === 'super-admin' || permissions.access_records"
          class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>

      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }}-{{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <financial-record-farm-add
      v-if="showFinancialRecordFarmAdd"
      :dialog="showFinancialRecordFarmAdd"
      :producers="producers"
      @closeDialog="reset()"
    />

    <confirm-destroy-dialog
      :title="'este registro'"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import FinancialRecords from "@/domain/records/financial-records/financial-records";
import Producers from "@/domain/producers/producers";
import Farms from "@/domain/farms/farms";

import FinancialRecordFarmAdd from "./FinancialRecordFarmAdd.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import SearchBar from "@/components/layouts/SearchBar";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

import {getItem} from '@/services/local-storage.service';

export default {
  name: "FinancialRecordsTable",
  components: {
    FinancialRecordFarmAdd,
    PrimaryButton,
    SearchBar,
    SimpleSelect,
    ConfirmDestroyDialog
  },
  data() {
    return {

      authority: true,
      adminId: [1, 2],
      payload: {},

      financialRecordService: null,
      producerService: null,
      farmService: null,
      financialIndexService: null,

      financialRecords: [],
      producers: [],
      farmDetails: {},
      recordSelected: {},

      dialog: false,
      showFinancialRecordFarmAdd: false,
      showConfirmDestroyDialog: false,
      search: {},
      harvestSearch: "",

      authoritySlug: "",
      permissions: ""
    }
  },

  computed: {
    isViewingAdmin() {
      return this.authority;
    },

    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "id"
        },
        {text: "Fazenda", value: "farm.name"},
        { text: "Proprietário", value: "farm.producer.user.name" },
        {
          text: "Safra",
          value: "harvest",
          filter: value => {
            if (!this.harvestSearch) return true
            return value === this.search.harvest;
          }
        },
        {text: "Data do Registro", value: "created_at"},
        {text: "Detalhes", value: "actions", sortable: false},
      ]
    },
  },

  methods: {
    getUserLogged() {
      const loader = this.$loading.show();

      LoginService
      .getUserLogged()
      .then((data) => {

        if (!this.adminId.includes(data.authority_id)) {
          this.authority = false;
        }

        loader.hide();
      })
      .catch(() => {
        location.reload();
        loader.hide();
      });
    },

    loadFinancialRecords(params) {
      this.financialRecords = [];

      this.financialRecordService.list(params).then(result => {
        result.data.map(item => {
          this.financialRecords.push(item);
        })
      })
      .catch(({response}) => {
        const {error} = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      });
    },

    loadProducers() {
      const loader          = this.$loading.show();
      this.producers        = [];

      this.producerService.all().then(result => {
        result.data.map(item => {
          this.producers.push(item);
        });
      });

      loader.hide();
    },

    loadFarmDetails(farmId) {
      const loader = this.$loading.show();
      this.farmDetails = {};
      
      this.farmService.getFarmDetails(farmId).then(result => {
        this.farmDetails = result.data;
      });

      loader.hide();
    },

    viewItem(item) {
      this.$router.push({
        name: 'financial-record-details',
        params: {farmId: item.farm_id, harvest: item.harvest}
      });
    },

    deleteItem(item) {
      this.recordSelected = {
        data : {
          'farm_id' : item.farm_id,
          'harvest' : item.harvest,
          'type'    : 'Financeiro'
        }
      };

      this.showConfirmDestroyDialog = true;
    },

    async destroy() {
      const loader = this.$loading.show();

      await this.financialRecordService
      .delete(this.recordSelected)
      .then(() => {
        loader.hide();
        this.loadFinancialRecords();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Registro financeiro removido com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Registro financeiro não foi removido.", {
          type: 'error'
        });
      });
    },

    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },

    addFinancialRecordFarm() {
      this.showFinancialRecordFarmAdd = true;
      this.loadProducers();
    },

    reset() {
      this.dialog                      = false;
      this.showFinancialRecordFarmAdd  = false;
      this.loadFinancialRecords();
    },

    filterByHarvest() {
      this.search.harvest = this.harvestSearch;
    },

    clearFilters() {
      this.harvestSearch = null;
    },
  },

  mounted() {
    this.loadFinancialRecords();
  },

  beforeMount() {
    this.getUserLogged();
    this.payload                = FinancialRecords.newData();
    this.financialRecordService = FinancialRecords;
    this.producerService        = Producers;
    this.farmService            = Farms;

    this.authoritySlug = getItem('authoritySlug');
    this.permissions   = JSON.parse(getItem('permissions'));
  },
};
</script>

<style scoped>
  ::v-deep .col-farm .v-toolbar__content {
    padding: 0;
  }

  .custom-container {
    border: 1px solid #E9D8A6;
    border-radius:5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  p {
    color: #000000;
  }
</style>
