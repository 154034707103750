<template>
  <div>
    <financial-records-table class="crud-data-table"></financial-records-table>
  </div>
</template>

<script>
import FinancialRecordsTable from "./FinancialRecordsTable";

export default {
  name: "FinancialRecords",
  components: {
    FinancialRecordsTable,
  },
};
</script>
