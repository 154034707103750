<template>
  <v-dialog :value="dialog" max-width="600" persistent>
    <v-card class="pa-4">
      <v-card-title class="text-h5">
        {{ message }}
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="closeDialog()" color="error" text> Cancelar </v-btn>

        <primary-button @callAction="redirect()" label="Sim" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryButton from "@/components/buttons/PrimaryButton";

export default {
  name: "ConfirmRedirectDialog",
  components: { PrimaryButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
    },

    routeName: {
      type: String,
    },

    routeParams: {
      type: Object
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },

    redirect() {
      this.$router.push({
        name: `${this.routeName}`,
        params: this.routeParams
      });
    },
  },
};
</script>
