<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-card-title class="form-modal-header">
          <v-btn color="white" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span class="text-h5 white--text">Novo Registro</span>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="save">Salvar</v-btn>
        </v-card-title>
        <v-card-text class="mt-12">
          <v-container>
            <v-row>
              <v-col cols="12" md="6" class="col-farm">
                <v-toolbar elevation="0" style="background: none">
                  <v-toolbar-title class="font-weight-bold">
                    Selecionar Fazenda
                  </v-toolbar-title>
                </v-toolbar>

                <v-container class="custom-container">
                  <v-row>
                    <v-col cols="12">
                      <custom-label label="Proprietário" />
                      <simple-select
                        :items="producers"
                        v-model="payload.producer_id"
                        placeholder="Produtor"
                        itemText="user.name"
                        itemValue="producer_id"
                        height="auto"
                        @input="loadFarms($event)"
                      />
                    </v-col>

                    <v-col>
                      <custom-label label="Fazenda" />
                      <simple-select
                        :items="farms"
                        :disabled="!producerSelected"
                        v-model="payload.farm_id"
                        placeholder="Fazenda"
                        itemText="name"
                        itemValue="id"
                        height="auto"
                        @input="loadFarmDetails($event)"
                      />
                    </v-col>

                    <v-col>
                      <custom-label label="Safra" />
                      <simple-text-field
                        placeholder="Safra"
                        mask="harvest"
                        :disabled="!producerSelected || payload.farm_id == null"
                        ref="inputHarvest"
                        v-model="farmDetails.harvestYearCalculation"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <p>Tamanho Fazenda</p>
                      <p v-if="farmDetails.farm_size">{{ farmDetails.farm_size + ' ha' }}</p>
                    </v-col>

                    <v-col cols="5">
                      <p>AS Responsável</p>
                      <p>{{ getAS() }}</p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>

              <v-col
                cols="12"
                md="6"
                class="col-index"
                v-if="financialIndexes.length > 0 && payload.farm_id"
              >
                <v-toolbar elevation="0" style="background: none">
                  <v-toolbar-title class="font-weight-bold">
                    Índices Financeiros
                  </v-toolbar-title>
                </v-toolbar>

                <v-container class="custom-container">
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      v-for="financialIndex in financialIndexes"
                      :key="financialIndex.index.id"
                    >
                      <custom-label :label="financialIndex.index.name" />
                      <simple-select
                        :items="grades"
                        placeholder="Selecione uma opção"
                        :itemValue="financialIndex.index.id.toString()"
                        @input="handleSelect(financialIndex.index.id, $event)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-redirect-dialog
      :message="'Registro foi criado anteriormente, deseja atualizar o registro já existente?'"
      :routeName="'financial-record-details'"
      :routeParams="{farmId: payload.farm_id, harvest: farmDetails.harvestYearCalculation}"
      :dialog.sync="showConfirmRedirectDialog"
      @closeDialog="closeConfirmRedirectDialog"
    />
  </div>
</template>

<script>
import SimpleTextField from "@/components/inputs/SimpleTextField";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import CustomLabel from "@/components/texts/CustomLabel";
import FinancialRecords from "@/domain/records/financial-records/financial-records";
import Farms from "@/domain/farms/farms";
import Contracts from "@/domain/contracts/contracts";

import ConfirmRedirectDialog from "@/components/dialogs/ConfirmRedirectDialog";

export default {
  name: "FertilityRecordFarmAdd",
  components: { SimpleTextField, SimpleSelect, CustomLabel, ConfirmRedirectDialog },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    producers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    payload: {},

    financialRecordService: null,
    farmService: null,
    contractService: null,

    financialIndexes: [],
    payloadIndexes: {},

    producerSelected: false,
    farms: [],
    farmDetails: {},
    grades: ["6.0", "6.5", "7.0", "7.5", "8.0", "8.5", "9.0", "9.5", "10.0"],
  
    showConfirmRedirectDialog: false
  }),

  methods: {
    async loadFarms(producerId) {
      const loader = this.$loading.show();
      this.farms = [];
      this.financialIndexes = [];
      this.payloadIndexes = {};
      this.farmDetails = {};
      this.payload.farm_id = null;

      await this.farmService.listByProducer(producerId).then((result) => {
        result.data.map((item) => {
          this.farms.push(item);
        });
      });

      await this.contractService
        .enabledIndexesByFinancialAndProducer(producerId)
        .then((result) => {
          result.data.map((item) => {
            this.financialIndexes.push(item);
            this.payloadIndexes[item.index.id] = null;
          });
        });

      this.producerSelected = true;
      loader.hide();
    },

    loadFarmDetails(farmId) {
      const loader = this.$loading.show();
      this.farmDetails = {};

      this.farmService.getFarmDetails(farmId).then((result) => {
        this.farmDetails = result.data;
      });

      loader.hide();
    },

    handleSelect(indexId, indexGrade) {
      this.payloadIndexes[indexId] = indexGrade;
    },

    toggleScrollBar(param) {
      let html = document.querySelector('html');
      html.setAttribute("style", `overflow: ${param};`);
    },

    close() {
      this.toggleScrollBar('auto');
      this.$emit("closeDialog");
    },

    closeConfirmRedirectDialog() {
      this.showConfirmRedirectDialog = false;
    },

    validate() {
      if (!this.producerSelected) {
        this.$toasted.show("Selecione um produtor.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      if (Object.keys(this.farmDetails).length === 0) {
        this.$toasted.show("Selecione uma fazenda.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      if (this.$refs.inputHarvest.value === '') {
        this.$toasted.show("O campo safra é obrigatório.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }

      return true;
    },

    save() {
      if (this.validate()) {
        const loader = this.$loading.show();

        this.payload.harvest = this.farmDetails.harvestYearCalculation;
        this.payload.indexes = this.payloadIndexes;

        this.financialRecordService
          .create(this.payload)
          .then(() => {
            loader.hide();
            this.close();

            this.$toasted.show("Registro financeiro cadastrado com sucesso.", {
              type: "success",
            });
          })
          .catch(({ response }) => {
            loader.hide();

            if (response.status === 409) {
              this.toggleScrollBar('auto');
              this.showConfirmRedirectDialog = true;
            } else {
              const { error } = response.data;

              this.$toasted.show(error, {
                type: "error",
              });
            }
          });
      }
    },

    getAS() {
      const producer = this.farmDetails.producer;
      return producer ? producer.responsible_success_advisor.name : "";
    },
  },

  watch: {
    dialog: {
      immediate: true,
      handler () {
        this.toggleScrollBar('hidden');
      }
    }
  },

  beforeMount() {
    this.payload = FinancialRecords.newData();
    this.financialRecordService = FinancialRecords;
    this.farmService = Farms;
    this.contractService = Contracts;
  },
};
</script>

<style scoped>
::v-deep .col-farm .v-toolbar__content {
  padding: 0;
}

::v-deep .col-index .v-toolbar__content {
  padding: 0;
}

.custom-container {
  border: 1px solid #e9d8a6;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}
</style>
