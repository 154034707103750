import FinancialRecordsService from "./financial-records.service";

class FinancialRecord {
    constructor(payload = {}) {
        Object.assign(this, {
            ...FinancialRecord.build(payload)
        });
    }

    static build(
        {
            id = '',
            grade = '',
            harvest = '',
            field_id = '',
            field = {},
            farm_id = '',
            farm = {},
            indexes = {},
            producer_id = '',
            producer = '',
            farmDetails = {},
            farmsByProducer = {},
            fields_by_farm = {},
            culture = '',
            recordDate = '',
            recordIql = '',
            iql_record_field = '',
            created_at = '',
            updated_at = ''
        }
    ) {
        return {
            id,
            grade,
            harvest,
            field_id,
            field,
            farm_id,
            farm,
            indexes,
            producer_id,
            producer,
            farmDetails,
            farmsByProducer,
            fields_by_farm,
            culture,
            recordDate,
            recordIql,
            iql_record_field,
            created_at,
            updated_at
        }
    }

    static newData(payload) {
        return new FinancialRecord(payload);
    }

    static list(params) {
        return new Promise((resolve, reject) => {
            FinancialRecordsService
                .list(params)
                .then(
                    success => {
                        const data = {...success};
                        const list = Object.assign({}, {data});
                        list.data = data.data.map(item => this.newData(item));
                        resolve(list);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static create(payload) {
        return new Promise((resolve, reject) => {
            FinancialRecordsService
                .create(payload)
                .then(
                    success => {
                        resolve(success);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static edit(payload) {
        return new Promise((resolve, reject) => {
            FinancialRecordsService
                .edit(payload)
                .then(
                    success => {
                        resolve(success);
                    },

                    error => {
                        reject(error);
                    }
                );
        })
    }

    static get(farmId, harvest) {
        return new Promise((resolve, reject) => {
            FinancialRecordsService
                .get(farmId, harvest)
                .then(
                    success => {
                        const fertilityRecord = this.newData(success.data.data);
                        resolve(fertilityRecord);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getFertilityRecordFields(farmId, harvest) {
        return new Promise((resolve, reject) => {
            FinancialRecordsService
                .getFertilityRecordFields(farmId, harvest)
                .then(
                    success => {
                        const response = success;
                        resolve(response);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getFertilityRecordByField(fieldId, harvest) {
        return new Promise((resolve, reject) => {
            FinancialRecordsService
                .getFertilityRecordByField(fieldId, harvest)
                .then(
                    success => {
                        const response = success;
                        response.data = this.newData(response.data.data);
                        resolve(response);
                    },

                    error => {
                        reject(error);
                    }
                )
        });
    }

    static delete(payload) {
        return new Promise((resolve, reject) => {
            FinancialRecordsService
                .delete(payload)
                .then(
                    success => {
                        resolve(success);
                    },
                
                    error => {
                        reject(error);
                    }
                );
        });
    }
}

export default FinancialRecord;
